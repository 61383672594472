<template>
    <div class="slider_panel">
        <div class="sidebar-banner">
            <img :src="$img.sidebar_banner" />
        </div>

        <div class="hot-data" v-if="dataShare && dataShare.length>0">
            <div class="hd">
                <h4 class="type">热心资源分享者</h4>
            </div>
            <ul class="data-items">
                <li
                    v-for="(item,index) in dataShare"
                    :key="index"
                    @click="goArticleCenter(item.uk)"
                    style="cursor:pointer"
                >
                    <span class="avatar">
                        <img :src="util.reImg(item.avatar) || defaultAvatar" />
                    </span>
                    <div class="data-cont">
                        <h4 class="title" :title="item.nick">{{item.nick}}</h4>
                        <p :class="item.riseFlag==1?'up':'down'">
                            <i v-if="item.riseFlag==1" class="jk-icon-shangsheng"></i>
                            <i v-if="item.riseFlag==-1" class="jk-icon-xiajiang"></i>
                            <span>一周内分享：{{item.number}}篇</span>
                        </p>
                    </div>
                </li>
            </ul>
        </div>

        <div class="hot-news" v-if="news && news.length>0">
            <div class="hd">
                <a href="javascript:void(0)" class="more" @click="goMoreArticle">更多</a>
                <h4 class="type">热门文章</h4>
            </div>
            <ul class="news-items">
                <li v-for="(item,index) in news" :key="index">
                    <a href="javascript:void(0)" class="item-cont" @click="goDetial(item.id)">
                        <h4 class="title">{{item.title}}</h4>
                        <span
                            class="jk-icon-dianquan"
                            v-if="item.download_point_count>0"
                        >{{item.download_point_count}}点券</span>
                    </a>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    name: "SidebarPanel",
    computed: {
        defaultAvatar: function () {
            return this.websiteConfigs.sourceUrl + "/upload/image/geek_avatar.png";
        }
    },
    props: ['news', 'dataShare'],
    methods: {
        //热门文章 - 更多按钮
        goMoreArticle() {
            this.$router.push("/article/index?hot=1");
        },
        //热门文章详情链接
        goDetial(e) {
            this.$router.push("/article/detail/" + e);
        },
        //热门分享者点击链接
        goArticleCenter(uk) {
            this.$router.push("/firm/article?uk=" + uk);
        },
    },
};
</script>


