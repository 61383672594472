<template>
    <div class="bread-crumb">
        <div class="container">
            <el-breadcrumb>
                <el-breadcrumb-item to="/">
                    <img
                        :src="$img.home"
                        style="vertical-align:middle;width:14px;line-height:14px;display:inline-block"
                    />
                    <span style="margin-left:6px;">首页</span>
                </el-breadcrumb-item>

                <el-breadcrumb-item
                    v-for="(item,index) in breadCardItems"
                    :key="index"
                    :to="url[index]"
                >{{ item }}</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
    </div>
</template>

<script>
export default {
    name: "BreadCrumb"
};
</script>
<script>
export default {
    data() {
        return {
            breadCardItems: [],
            url: []
        };
    },

    //页面挂载之后,解析路由，给出面包屑，路由里面一定要含有breadCom组件的path
    created: function () {
        this.loadChange(this.$route);
    },

    methods: {
        //面包屑解决方案
        loadChange(route) {
            this.breadCardItems = route.meta.BreadCrumbs;
            if (route.meta.hasOwnProperty("url") && route.meta.url.length > 0) {
                this.url = route.meta.url;
            }
        }
    },

    watch: {
        $route(to, from) {
            this.loadChange(to);
        }
    }
};
</script>


