<template>
    <div class="about-main">
        <top-nav></top-nav>

        <header-guide></header-guide>

        <bread-crumb></bread-crumb>

        <div
            class="sp-banner"
            :style="{backgroundImage:'url('+$img.help_banner+')'}"
        >
            <div class="container">
                <div class="sp-banner-txt">帮助中心</div>
            </div>
        </div>

        <div class="main">
            <div class="container">

                <aside class="aside-panel">
                    <el-collapse
                        v-model="activeName"
                        accordion
                    >
                        <el-collapse-item
                            v-for="cateItem in problem"
                            :title="cateItem.cate"
                            :key="cateItem.cate_id"
                            :name="cateItem.cate_id"
                        >
                            <ul class="problem-items">
                                <li
                                    v-for="item of cateItem.list"
                                    :key="item.id"
                                >
                                    <a
                                        :class="{'active':currentIndex==item.id}"
                                        href="javascript:void(0)"
                                        @click="changeDetail(item.id)"
                                        v-text="item.title"
                                    ></a>
                                </li>
                            </ul>
                        </el-collapse-item>
                    </el-collapse>
                </aside>

                <div class="contarea-panel">
                    <div class="contarea">
                        <div class="about-txt-cont">
                            <h4
                                class="title"
                                v-text="titDetail.title"
                            ></h4>
                            <div class="question-item">
                                <div
                                    class="cont"
                                    v-html="titDetail.content"
                                ></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="sidebar-panel">
                    <sidebar-panel></sidebar-panel>
                </div>
            </div>
        </div>

        <global-footer></global-footer>
    </div>
</template>

<script>
import TopNav from "@/components/common/TopNav.vue";
import HeaderGuide from "@/components/common/HeaderGuide.vue";
import BreadCrumb from "@/components/common/BreadCrumb.vue";
import SidebarPanel from "@/components/common/SidebarPanel.vue";
import GlobalFooter from "@/components/common/GlobalFooter.vue";

export default {
    name: "helps",

    metaInfo() {
        return {
            title: this.article_title + " - 询龙网",
            meta: [
                {
                    name: "keywords",
                    content: "工程极客,工程技术项目,咨询人才,咨询团队,在家工作，众包平台,工程众包,造价工程,项目测绘,项目评估,工程勘测,工程设计,工程造价,工程监理,财务会计,税务服务,工程法务,技术承包,资料外包,造价工程师,装修设计"
                }, {
                    name: "description",
                    content: this.article_content
                }
            ]
        }
    },

    components: {
        TopNav, //头部吊顶
        HeaderGuide, //头部指南
        BreadCrumb, //面包屑导航
        SidebarPanel, //右边公共模块
        GlobalFooter //公共底部
    },

    created() {
        this.getProNav();//获取分类
        this.urlRoute();
    },

    data() {
        return {
            currentIndex: 0,
            activeName: '1',
            problem: [
                {
                    name: "1",
                    title: "常见问题",
                    openFlag: true,
                    children: [
                        {
                            nameTitle: "如何退保证金?"
                        }
                    ]
                }
            ],
            titDetail: [
                {
                    title: "",
                    content: ""
                }
            ],
            article_title: '',
            article_content: ''
        };
    },

    methods: {
        //获取分类
        getProNav() {
            let _this = this;
            this.post("/article/index/help_center", {}, function (res) {
                if (res.code == 200) {
                    _this.problem = res.data;
                }
            });
        },

        //文章详情
        changeDetail(id) {
            let _this = this;
            _this.currentIndex = id; //激活文章标题选中项
            this.post(
                "/article/index/detail",
                {
                    id: id //查询的id
                },
                function (res) {
                    if (res.code == 200) {
                        //seo
                        _this.article_title = res.data.title;
                        _this.article_content = res.data.content.replace(/<[^>].*?>/g, "").replace(/&nbsp;/g, "").substr(0, 300);//截取文章内容，去除html标签

                        _this.titDetail = res.data;
                    }
                }
            );
        },

        //路由输入参数直接访问
        urlRoute() {
            let _this = this;
            let cate = _this.$route.params.id; //获取路由参数
            let get_article_id = _this.$route.query.id;
            _this.activeName = Number(cate); //动态绑定收缩扩张

            _this.post(
                "/article/index/get_list_cate",
                {
                    cate: cate
                },
                function (res) {
                    if (res.code == 200) {
                        //获取列表下默认第一个文章id
                        let article_id = res.data[cate]["list"][0]["id"];
                        if (get_article_id) {
                            _this.changeDetail(get_article_id); //获取文章内容
                        } else {
                            _this.changeDetail(article_id); //获取文章内容
                        }
                    }
                }
            );
        }
    },

    watch: {
        $route() {
            this.urlRoute();
        }
    }
};
</script>

<style lang="less">
.el-collapse {
    border: none;
    width: 220px;
}
.el-collapse-item {
    &.is-active {
        .el-collapse-item__header {
            background: #587ddc;
            color: #fff;
        }
    }
}
.el-collapse-item__header {
    padding: 0 20px;
    width: 100%;
    height: 40px;
    border: none;
    font-size: 16px;
    line-height: 40px;
    color: #333;
    .el-collapse-item__arrow {
        display: none;
    }
}
.el-collapse-item__wrap {
    border: none;
}
.el-collapse-item__content {
    padding: 0;
}
.problem-items {
    padding: 20px 30px;
    a {
        height: 30px;
        overflow: hidden;
        display: block;
        line-height: 30px;
        font-size: 14px;
        color: #666;
        -webkit-transition: all 0.4s ease;
        -moz-transition: all 0.4s ease;
        -o-transition: all 0.4s ease;
        transition: all 0.4s ease;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        &:hover {
            color: #587ddc;
        }
        &.active {
            color: #587ddc;
        }
    }
}
.question-item {
    width: 100%;
    margin: 30px 0;
    .title {
        font-size: 14px;
        color: #587ddc;
        line-height: 24px;
        margin: 0;
        font-weight: 400;
    }
    .cont {
        margin-top: 10px;
        font-size: 14px;
        color: #666;
        line-height: 24px;
    }
}
.contarea img {
    max-width: 618px;
}
</style>





