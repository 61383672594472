<template>
    <div
        class="header-guide"
        :class="{'is-fixed': isFixed}"
    >

        <div class="container">
            <div class="guide-left">
                <h1 class="logo">
                    <a href="/">
                        <el-image :src="$img.logo"></el-image>
                    </a>
                </h1>

                <ul class="guide-nav">
                    <li>
                        <a href="javascript:;">
                            <router-link
                                class="nav-link"
                                to="/"
                            >首页</router-link>
                        </a>
                    </li>
                    <li>
                        <a href="javascript:;">
                            <router-link
                                class="nav-link"
                                to="/geek/list"
                            >找工程极客</router-link>
                        </a>
                    </li>
                    <li>
                        <a href="javascript:;">
                            <router-link
                                class="nav-link"
                                to="/task/list"
                            >找快包项目</router-link>
                        </a>
                    </li>
                    <li>
                        <a href="javascript:;">
                            <router-link
                                class="nav-link"
                                to="/article/index"
                            >资源分享</router-link>
                        </a>
                    </li>
                </ul>
            </div>

            <div class="guide-right searchBox">
                <el-button
                    v-if="userInfo.isLogin==false || userInfo.info.user_type == 2"
                    class="publish-btn"
                    @click="addtask"
                >免费发布项目</el-button>

                <div class="sel-type-wrap">
                    <el-select
                        v-model="searchTypeValue"
                        @change="changeSearchTypeEvent"
                        class="search-type search_type"
                    >
                        <el-option
                            value="项目"
                            label="项目"
                        ></el-option>
                        <el-option
                            value="极客"
                            label="极客"
                        ></el-option>
                    </el-select>
                </div>

                <div class="hd-form">
                    <input
                        class="seach-ipt"
                        type="text"
                        v-model="keywords"
                        placeholder="请输入搜索关键字"
                        @keyup.enter.down="search"
                    />
                    <el-button @click="search">搜索</el-button>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
    name: "HeaderGuide",
    computed: {
        ...mapState(["userInfo"]),
        ...mapState(["websitInfo"])
    },
    props: ["keyword"],
    data() {
        return {
            keywords: "",
            searchTypeValue: "项目",
            isFixed: false // 是否滚动固定搜索栏
        };
    },
    created() {
        var _this = this;

        //赋值父组件传进来的关键字
        setTimeout(() => {
            this.keywords = this.keyword ? this.keyword : "";
        }, 100);

        //默认设置搜索类型
        let searchtypeValue = sessionStorage.getItem("searchTypeValue");
        if (searchtypeValue) {
            this.searchTypeValue = searchtypeValue;
        } else {
            sessionStorage.setItem("searchTypeValue", this.searchTypeValue);
        }

        /* 滚动跟随搜索栏  */
        window.onscroll = function () {
            //为了保证兼容性，这里取两个值，哪个有值取哪一个
            //scrollTop就是触发滚轮事件时滚轮的高度
            var scrollTop =
                document.documentElement.scrollTop || document.body.scrollTop;
            if (scrollTop > 40) {
                _this.isFixed = true;
            } else {
                _this.isFixed = false;
            }
            _this.$emit("winscroll", scrollTop);
        };
    },
    methods: {
        ...mapActions("userInfo", ["loginOut"]),
        goDetail() {
            this.$router.push("/article/index");
        },
        regClick() {
            this.$router.push("/register/index/master");
        },
        loginClick() {
            this.$router.push("/login/index?type=login");
        },
        go_to(name) {
            if (this.timer) {
                clearInterval(this.timer);
            }
            this.$router.push({ name: name });
        },

        go_Logingout(n = 0) {
            this.loginOut(); //退出登录
            if (n == 0) {
                this.$router.push("/");
            } else {
                this.$router.push("/login/index");
            }
        },

        //改变搜索类型事件
        changeSearchTypeEvent(e) {
            sessionStorage.setItem("searchTypeValue", e);
        },

        //搜索
        search() {
            let searchTypeValue = sessionStorage.getItem("searchTypeValue");
            if (searchTypeValue == "项目") {
                this.$router.push("/task/list?keyword=" + this.keywords);
            } else {
                this.$router.push("/geek/list?keyword=" + this.keywords);
            }
        },

        //跳转到发布项目页面
        addtask() {
            //用户已登录
            if (this.userInfo.isLogin == true) {
                //雇主
                if (this.userInfo.info.user_type == 2) {
                    //是否需要认证才能发布项目（1.需要认证；0.不需要认证）
                    if (this.userInfo.config.is_atte == 1) {
                        if (
                            this.userInfo.attestation != "" &&
                            this.userInfo.info.attestation == 2
                        ) {
                            //已经认证
                            this.$router.push("/task/publish"); //跳转到发布项目
                        } else {
                            this.$message.error(
                                "请先完成雇主认证，才能发布项目"
                            );
                            setTimeout(() => {
                                this.$router.push("/user/attest");
                            }, 1000);
                        }
                    } else {
                        this.$router.push("/task/publish"); //跳转到发布项目
                    }
                } else {
                    this.$message.error("只有雇主才能发布项目，请注册成为雇主");
                }
            } else {
                this.$message.error("请先登录后再操作");
                this.$router.push("/login/index?type=login");
            }
        }
    }
};
</script>

<style lang="less">
.search_type {
    .el-input__inner {
        height: 90% !important;
    }
}
</style>

